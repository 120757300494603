export enum TranslationKey {

    aboutToBeBlocked = "aboutToBeBlocked",
    acceptedCurrency = "acceptedCurrency",
    accountNumber = "accountNumber",
    addSupportingDocument = "addSupportingDocument",
    additionalPaymentMethods = "additionalPaymentMethods",
    addressNotFound = "addressNotFound",
    apply = "apply",
    approved = "approved",
    arePoliticalPerson = "arePoliticalPerson",
    areUSCitizen = "areUSCitizen",
    back = "back",
    backSide = "backSide",
    backSideCC = "backSideCC",
    backToTrade = "backToTrade",
    bankAddress = "bankAddress",
    bankName = "bankName",
    beneficiaryName = "beneficiaryName",
    boardActionCreateAccount = "board.action.create.account",
    boardActionLogIn = "board.action.log.in",
    boardActionResendEmail = "board.action.resend.email",
    boardActionResendLink = "board.action.resend.link",
    boardActionResetPassword = "board.action.reset.password",
    boardActionSendResetLink = "board.action.send.reset.link",
    boardActionSendVerificationCode = "board.action.send.verification.code",
    boardBenefitAdvancedPlatformXCite = "board.benefit.advancedPlatformXCite",
    boardBenefitDedicatedManager = "board.benefit.dedicatedManager",
    boardBenefitOpenAccount = "board.benefit.openAccount",
    boardBenefitRegulationCySEC = "board.benefit.regulationCySEC",
    boardBenefitRegulationFSCA = "board.benefit.regulationFSCA",
    boardBenefitRegulationHCMC = "board.benefit.regulationHCMC",
    boardBenefitRegulationMFSA = "board.benefit.regulationMFSA",
    boardBenefitTrustSince = "board.benefit.trustSince",
    boardBenefitZeroCommission = "board.benefit.zeroCommission",
    boardDocumentTitleChangePassword = "board.document.title.ChangePassword",
    boardDocumentTitleCheckInbox = "board.document.title.CheckInbox",
    boardDocumentTitleForgotPassword = "board.document.title.ForgotPassword",
    boardDocumentTitleLogin = "board.document.title.Login",
    boardDocumentTitleResetPassword = "board.document.title.ResetPassword",
    boardDocumentTitleSignUp = "board.document.title.SignUp",
    boardDocumentTitleVerification = "board.document.title.Verification",
    boardEmailMe = "board.email.me",
    boardEnterEmail = "board.enter.email",
    boardForgotPassword = "board.forgot.password",
    boardFormContinueWithFacebook = "board.form.continue.with.facebook",
    boardFormContinueWithGoogle = "board.form.continue.with.google",
    boardFormSignInWithApple = "board.form.SignIn.with.apple",
    boardFormSignUpWithApple = "board.form.SignUp.with.apple",
    boardFormSubtitleActionLogin = "board.form.subtitle.action.login",
    boardFormSubtitleActionSignUp = "board.form.subtitle.action.signUp",
    boardFormSubtitleChangePassword = "board.form.subtitle.changePassword",
    boardFormSubtitleLogin = "board.form.subtitle.login",
    boardFormSubtitleSignUp = "board.form.subtitle.signUp",
    boardFormSubtitleVerificationCode = "board.form.subtitle.verificationCode",
    boardFormTitleChangePassword = "board.form.title.changePassword",
    boardFormTitleCheckInbox = "board.form.title.checkInbox",
    boardFormTitleForgotPassword = "board.form.title.forgotPassword",
    boardFormTitleLogin = "board.form.title.login",
    boardFormTitleResetPassword = "board.form.title.resetPassword",
    boardFormTitleSignUp = "board.form.title.signUp",
    boardFormTitleVerificationCode = "board.form.title.verificationCode",
    boardFormTitleVerificationMethod = "board.form.title.verificationMethod",
    boardPhoneCallMe = "board.phoneCall.me",
    boardReceivedCodeEmail = "board.received.code.email",
    boardReceivedCodePhoneCall = "board.received.code.phoneCall",
    boardReceivedCodeSms = "board.received.code.sms",
    boardResetPasswordLinkSent = "board.reset.password.link.sent",
    boardResetPasswordSuccessfully = "board.reset.password.successfully",
    boardSignUpConfirmationLinkSent = "board.sign.up.confirmation.link.sent",
    boardSignupPasswordErrorRegEx0 = "board.signup.password.error.RegEx0",
    boardSignupPasswordErrorRegEx1 = "board.signup.password.error.RegEx1",
    boardSignupPasswordErrorRegEx2 = "board.signup.password.error.RegEx2",
    boardSignupPasswordErrorRegEx3 = "board.signup.password.error.RegEx3",
    boardSignupPasswordErrorRegEx4 = "board.signup.password.error.RegEx4",
    boardSignupPasswordErrorRegEx5 = "board.signup.password.error.RegEx5",
    boardSmsMe = "board.sms.me",
    boardVerificationDifferentOptions = "board.verification.differentOptions",
    boardVerificationEmail = "board.verification.email",
    boardVerificationEmailSent = "board.verification.email.sent",
    boardVerificationError = "board.verification.error",
    boardVerificationMehodsAvailable = "board.verificationMehods.available",
    boardVerificationResendCode = "board.verification.resendCode",
    boardVerificationSms = "board.verification.sms",
    browserVersionNotSupported = "browserVersionNotSupported",
    callMe = "callMe",
    callMeMessage = "callMe.message",
    cancel = "cancel",
    cancelDocumentConfirmation = "cancelDocumentConfirmation",
    cancelRequest = "cancelRequest",
    cancelRequestQ = "cancelRequestQ",
    cardsEndWith = "cardsEndWith",
    ccBackSide = "ccBackSide",
    ccFrontSide = "ccFrontSide",
    changeAnswers = "changeAnswers",
    choosePreferredBankCurrency = "choosePreferredBankCurrency",
    chooseYourProvider = "chooseYourProvider",
    clientDeclaration = "clientDeclaration",
    completeLater = "completeLater",
    completed = "completed",
    confirm = "confirm",
    continueWith = "continueWith",
    continue_ = "continue_",
    copiedToClipboard = "copiedToClipboard",
    copy = "copy",
    countryCode = "countryCode",
    createdAt = "createdAt",
    creditCard = "creditCard",
    creditCardApproveCondition = "creditCardApproveCondition",
    currency = "currency",
    cvvInfo = "cvvInfo",
    dateOfBirth = "dateOfBirth",
    depositLimitAlreadyFilled = "depositLimitAlreadyFilled",
    depositLimitConfirmation = "depositLimitConfirmation",
    depositLimitFailed = "depositLimitFailed",
    depositLimitSuccessful = "depositLimitSuccessful",
    depositLimitWarning = "depositLimitWarning",
    depositMinAmount = "depositMinAmount",
    depositPageSubtitleDepositDifferentAmount = "deposit.page.subtitle.DepositDifferentAmount",
    depositPageSubtitleDepositFailed = "deposit.page.subtitle.DepositFailed",
    depositPageSubtitleDepositOption = "deposit.page.subtitle.DepositOption",
    depositPageSubtitleDepositSuccessful = "deposit.page.subtitle.DepositSuccessful",
    depositPageSubtitleDepositTopUp = "deposit.page.subtitle.DepositTopUp",
    depositPageTitleDepositDifferentAmount = "deposit.page.title.DepositDifferentAmount",
    depositPageTitleDepositFailed = "deposit.page.title.DepositFailed",
    depositPageTitleDepositOption = "deposit.page.title.DepositOption",
    depositPageTitleDepositSuccessful = "deposit.page.title.DepositSuccessful",
    depositPageTitleDepositTopUp = "deposit.page.title.DepositTopUp",
    depositPaymentMethodAstroPay = "deposit.payment.method.astroPay",
    depositPaymentMethodEasyEFT = "deposit.payment.method.easyEFT",
    depositPaymentMethodEcoPayz = "deposit.payment.method.ecoPayz",
    depositPaymentMethodEcoVoucher = "deposit.payment.method.ecoVoucher",
    depositPaymentMethodGlobePay = "deposit.payment.method.globePay",
    depositPaymentMethodKlarna = "deposit.payment.method.klarna",
    depositPaymentMethodMastercard = "deposit.payment.method.mastercard",
    depositPaymentMethodNeteller = "deposit.payment.method.neteller",
    depositPaymentMethodPaypal = "deposit.payment.method.paypal",
    depositPaymentMethodPsc = "deposit.payment.method.psc",
    depositPaymentMethodSafetyPay = "deposit.payment.method.safetyPay",
    depositPaymentMethodSkrill = "deposit.payment.method.skrill",
    depositPaymentMethodSofort = "deposit.payment.method.sofort",
    depositPaymentMethodTapPayments = "deposit.payment.method.tapPayments",
    depositPaymentMethodTrustly = "deposit.payment.method.trustly",
    depositPaymentMethodVisa = "deposit.payment.method.visa",
    depositPaymentMethodWireTransfer = "deposit.payment.method.wireTransfer",
    depositPaymentMethodZimpler = "deposit.payment.method.zimpler",
    depositProtectedTrades = "depositProtectedTrades",
    depositTopUpOptionDescription1 = "deposit.top.up.option.description.1",
    depositTopUpOptionDescription2 = "deposit.top.up.option.description.2",
    depositTopUpOptionDescription3 = "deposit.top.up.option.description.3",
    depositTopUpOptionDescription4 = "deposit.top.up.option.description.4",
    disableVerificationCodeDescription = "disableVerificationCodeDescription",
    document = "document",
    documentAdditional = "document.additional",
    documentPageSubTitle1 = "document.page.sub.title.1",
    documentPageSubTitle2 = "document.page.sub.title.2",
    documentPageSubTitle3 = "document.page.sub.title.3",
    documentPageSubTitle4 = "document.page.sub.title.4",
    documentPageSubTitle5 = "document.page.sub.title.5",
    documentPageSubTitle6 = "document.page.sub.title.6",
    documentPageSubTitle7 = "document.page.sub.title.7",
    documentPageSubTitle8 = "document.page.sub.title.8",
    documentPageTitle1 = "document.page.title.1",
    documentPageTitle2 = "document.page.title.2",
    documentPageTitle3 = "document.page.title.3",
    documentPageTitle4 = "document.page.title.4",
    documentPageTitle5 = "document.page.title.5",
    documentPageTitle6 = "document.page.title.6",
    documentPageTitle7 = "document.page.title.7",
    documentPageTitle8 = "document.page.title.8",
    documentReadable = "documentReadable",
    errorFileReading = "error.fileReading",
    errorFileUpload = "error.fileUpload",
    errorGeneral = "error.general",
    expiryDate = "expiryDate",
    filledOut = "filledOut",
    finishLater = "finishLater",
    frontSide = "frontSide",
    frontSideCC = "frontSideCC",
    fullyProtectedTrades = "fullyProtectedTrades",
    goToPlatform = "goToPlatform",
    gotIt = "gotIt",
    great = "great",
    iAcceptAgreeWithAllAbove = "iAcceptAgreeWithAllAbove",
    iAgree = "iAgree",
    iban = "iban",
    importantNotice = "importantNotice",
    installForFree = "installForFree",
    invalidDocuments = "invalidDocuments",
    kycDocumentTitleDepositDifferentAmount = "kyc.document.title.DepositDifferentAmount",
    kycDocumentTitleDepositFailed = "kyc.document.title.DepositFailed",
    kycDocumentTitleDepositOption = "kyc.document.title.DepositOption",
    kycDocumentTitleDepositSuccessful = "kyc.document.title.DepositSuccessful",
    kycDocumentTitleDepositTopUp = "kyc.document.title.DepositTopUp",
    kycDocumentTitleKycAddress = "kyc.document.title.KycAddress",
    kycDocumentTitleKycAddressVerification = "kyc.document.title.KycAddressVerification",
    kycDocumentTitleKycAppropriate = "kyc.document.title.KycAppropriate",
    kycDocumentTitleKycCnmv = "kyc.document.title.KycCnmv",
    kycDocumentTitleKycCongratulation = "kyc.document.title.KycCongratulation",
    kycDocumentTitleKycFilledOut = "kyc.document.title.KycFilledOut",
    kycDocumentTitleKycFinancialDetails = "kyc.document.title.KycFinancialDetails",
    kycDocumentTitleKycFinancialDetailsInternational = "kyc.document.title.KycFinancialDetailsInternational",
    kycDocumentTitleKycIdentityVerification = "kyc.document.title.KycIdentityVerification",
    kycDocumentTitleKycInAppropriate = "kyc.document.title.KycInAppropriate",
    kycDocumentTitleKycPaymentVerification = "kyc.document.title.KycPaymentVerification",
    kycDocumentTitleKycPersonalInfo = "kyc.document.title.KycPersonalInfo",
    kycDocumentTitleKycSignature = "kyc.document.title.KycSignature",
    kycDocumentTitleKycTradingExperience = "kyc.document.title.KycTradingExperience",
    kycDocumentTitleKycTradingExperienceIntentions = "kyc.document.title.KycTradingExperienceIntentions",
    kycDocumentTitleKycTradingExperienceKnowledge = "kyc.document.title.KycTradingExperienceKnowledge",
    kycDocumentTitleKycTradingExperienceKnowledge2 = "kyc.document.title.KycTradingExperienceKnowledge2",
    kycDocumentTitleKycTradingFamiliarity = "kyc.document.title.KycTradingFamiliarity",
    kycDocumentTitleKycTradingProfile = "kyc.document.title.KycTradingProfile",
    kycDocumentTitleKycWelcome = "kyc.document.title.KycWelcome",
    kycFormFieldAddress = "kyc.form.field.Address",
    kycFormFieldAddressVerificationDocType = "kyc.form.field.addressVerificationDocType",
    kycFormFieldAddressVerificationDocTypeExpiration1 = "kyc.form.field.addressVerificationDocType.expiration.1",
    kycFormFieldAddressVerificationDocTypeExpiration2 = "kyc.form.field.addressVerificationDocType.expiration.2",
    kycFormFieldAddressVerificationDocTypeExpiration3 = "kyc.form.field.addressVerificationDocType.expiration.3",
    kycFormFieldAddressVerificationDocTypeExpiration4 = "kyc.form.field.addressVerificationDocType.expiration.4",
    kycFormFieldAreaOfEducationId = "kyc.form.field.AreaOfEducationId",
    kycFormFieldBirthDay = "kyc.form.field.birthDay",
    kycFormFieldBirthMonth = "kyc.form.field.birthMonth",
    kycFormFieldBirthYear = "kyc.form.field.birthYear",
    kycFormFieldBrokersNames = "kyc.form.field.BrokersNames",
    kycFormFieldCFDsFamiliarityId = "kyc.form.field.CFDsFamiliarityId",
    kycFormFieldCFDsFamiliarityId_checkbox = "kyc.form.field.CFDsFamiliarityId_checkbox",
    kycFormFieldCardCvv = "kyc.form.field.cardCvv",
    kycFormFieldCardExpirationMonth = "kyc.form.field.cardExpirationMonth",
    kycFormFieldCardExpirationYear = "kyc.form.field.cardExpirationYear",
    kycFormFieldCardName = "kyc.form.field.cardName",
    kycFormFieldCardNumber = "kyc.form.field.cardNumber",
    kycFormFieldCity = "kyc.form.field.City",
    kycFormFieldComplianceQuizCDFShareQuestionAnwserId = "kyc.form.field.ComplianceQuizCDFShareQuestionAnwserId",
    kycFormFieldComplianceQuizForexQuestionAnswerId = "kyc.form.field.ComplianceQuizForexQuestionAnswerId",
    kycFormFieldComplianceQuizLeverageQuestionAnwserId = "kyc.form.field.ComplianceQuizLeverageQuestionAnwserId",
    kycFormFieldComplianceQuizMarketMovesQuestionAnswerId = "kyc.form.field.ComplianceQuizMarketMovesQuestionAnswerId",
    kycFormFieldComplianceQuizRiskQuestionAnswerId = "kyc.form.field.ComplianceQuizRiskQuestionAnswerId",
    kycFormFieldComplianceQuizStopLossOrderAnswerId = "kyc.form.field.ComplianceQuizStopLossOrderAnswerId",
    kycFormFieldConfirmPassword = "kyc.form.field.confirmPassword",
    kycFormFieldCountryCode = "kyc.form.field.CountryCode",
    kycFormFieldCountryOfBirthId = "kyc.form.field.CountryOfBirthId",
    kycFormFieldCountryOfEmploymentIds = "kyc.form.field.CountryOfEmploymentIds",
    kycFormFieldCountryOfNationalityId = "kyc.form.field.CountryOfNationalityId",
    kycFormFieldCountryOfResidenceId = "kyc.form.field.CountryOfResidenceId",
    kycFormFieldCountryOfTaxRegistrationId = "kyc.form.field.CountryOfTaxRegistrationId",
    kycFormFieldDay = "kyc.form.field.day",
    kycFormFieldDoesNotOwnTinNumber = "kyc.form.field.DoesNotOwnTinNumber",
    kycFormFieldEducationLevel = "kyc.form.field.EducationLevel",
    kycFormFieldEmail = "kyc.form.field.Email",
    kycFormFieldEmploymentDetailsId = "kyc.form.field.EmploymentDetailsId",
    kycFormFieldEmploymentDetailsType = "kyc.form.field.EmploymentDetailsType",
    kycFormFieldEmploymentStatus = "kyc.form.field.EmploymentStatus",
    kycFormFieldErrorAddress = "kyc.form.field.error.Address",
    kycFormFieldErrorAreaOfEducationId = "kyc.form.field.error.AreaOfEducationId",
    kycFormFieldErrorBirthDay = "kyc.form.field.error.birthDay",
    kycFormFieldErrorBirthMonth = "kyc.form.field.error.birthMonth",
    kycFormFieldErrorBirthYear = "kyc.form.field.error.birthYear",
    kycFormFieldErrorCFDsFamiliarityId = "kyc.form.field.error.CFDsFamiliarityId",
    kycFormFieldErrorCardCvv = "kyc.form.field.error.cardCvv",
    kycFormFieldErrorCardExpirationMonth = "kyc.form.field.error.cardExpirationMonth",
    kycFormFieldErrorCardExpirationYear = "kyc.form.field.error.cardExpirationYear",
    kycFormFieldErrorCardName = "kyc.form.field.error.cardName",
    kycFormFieldErrorCardNumber = "kyc.form.field.error.cardNumber",
    kycFormFieldErrorCashDepositsAndSavingsPortfolioSizeId = "kyc.form.field.error.CashDepositsAndSavingsPortfolioSizeId",
    kycFormFieldErrorCity = "kyc.form.field.error.City",
    kycFormFieldErrorConfirmPassword = "kyc.form.field.error.confirmPassword",
    kycFormFieldErrorCountryCode = "kyc.form.field.error.CountryCode",
    kycFormFieldErrorCountryOfBirthId = "kyc.form.field.error.CountryOfBirthId",
    kycFormFieldErrorCountryOfEmploymentIds = "kyc.form.field.error.CountryOfEmploymentIds",
    kycFormFieldErrorCountryOfNationalityId = "kyc.form.field.error.CountryOfNationalityId",
    kycFormFieldErrorCountryOfResidenceId = "kyc.form.field.error.CountryOfResidenceId",
    kycFormFieldErrorCountryOfTaxRegistrationId = "kyc.form.field.error.CountryOfTaxRegistrationId",
    kycFormFieldErrorDay = "kyc.form.field.error.day",
    kycFormFieldErrorDebtInstrumentsPortfolioSizeId = "kyc.form.field.error.DebtInstrumentsPortfolioSizeId",
    kycFormFieldErrorDerivativesPortfolioSizeId = "kyc.form.field.error.DerivativesPortfolioSizeId",
    kycFormFieldErrorEducationLevel = "kyc.form.field.error.EducationLevel",
    kycFormFieldErrorEmail = "kyc.form.field.error.Email",
    kycFormFieldErrorEmailAlreadyInUse = "kyc.form.field.error.email.already.in.use",
    kycFormFieldErrorEmailNeedConfirmation = "kyc.form.field.error.email.need.confirmation",
    kycFormFieldErrorEmploymentDetailsId = "kyc.form.field.error.EmploymentDetailsId",
    kycFormFieldErrorEmploymentDetailsType = "kyc.form.field.error.EmploymentDetailsType",
    kycFormFieldErrorEmploymentStatus = "kyc.form.field.error.EmploymentStatus",
    kycFormFieldErrorEstimatedAnnualIncome = "kyc.form.field.error.EstimatedAnnualIncome",
    kycFormFieldErrorExchangeTradedFundsFamiliarityId = "kyc.form.field.error.ExchangeTradedFundsFamiliarityId",
    kycFormFieldErrorExchangeTradedProductsPortfolioSizeId = "kyc.form.field.error.ExchangeTradedProductsPortfolioSizeId",
    kycFormFieldErrorExperienceLength = "kyc.form.field.error.ExperienceLength",
    kycFormFieldErrorFinancialDerivativesFamiliarityId = "kyc.form.field.error.FinancialDerivativesFamiliarityId",
    kycFormFieldErrorFirstName = "kyc.form.field.error.FirstName",
    kycFormFieldErrorFullName = "kyc.form.field.error.FullName",
    kycFormFieldErrorIdentityCardNumber = "kyc.form.field.error.IdentityCardNumber",
    kycFormFieldErrorImage = "kyc.form.field.error.image",
    kycFormFieldErrorIndustryDescription = "kyc.form.field.error.IndustryDescription",
    kycFormFieldErrorIndustryId = "kyc.form.field.error.IndustryId",
    kycFormFieldErrorKnowledgeOfCFDTrading = "kyc.form.field.error.KnowledgeOfCFDTrading",
    kycFormFieldErrorLastName = "kyc.form.field.error.LastName",
    kycFormFieldErrorListedSharesId = "kyc.form.field.error.ListedSharesId",
    kycFormFieldErrorLoginPassword = "kyc.form.field.error.loginPassword",
    kycFormFieldErrorMandatory = "kyc.form.field.error.mandatory",
    kycFormFieldErrorMonth = "kyc.form.field.error.month",
    kycFormFieldErrorNewPassword = "kyc.form.field.error.newPassword",
    kycFormFieldErrorOtherEmploymentDetails = "kyc.form.field.error.OtherEmploymentDetails",
    kycFormFieldErrorPhoneNumber = "kyc.form.field.error.PhoneNumber",
    kycFormFieldErrorPostalCode = "kyc.form.field.error.PostalCode",
    kycFormFieldErrorPreviousEmploymentDetailsType = "kyc.form.field.error.PreviousEmploymentDetailsType",
    kycFormFieldErrorPreviousIndustryId = "kyc.form.field.error.PreviousIndustryId",
    kycFormFieldErrorProductGovernanceDesiredRiskTypeId = "kyc.form.field.error.ProductGovernanceDesiredRiskTypeId",
    kycFormFieldErrorProductGovernanceInvestingObjectiveTypeId = "kyc.form.field.error.ProductGovernanceInvestingObjectiveTypeId",
    kycFormFieldErrorProductGovernanceYearlyDisposableIncomeCategoryId = "kyc.form.field.error.ProductGovernanceYearlyDisposableIncomeCategoryId",
    kycFormFieldErrorReferralCode = "kyc.form.field.error.referralCode",
    kycFormFieldErrorSavingAndInvestmentId = "kyc.form.field.error.SavingAndInvestmentId",
    kycFormFieldErrorSharesPortfolioSizeId = "kyc.form.field.error.SharesPortfolioSizeId",
    kycFormFieldErrorSignUpPassword = "kyc.form.field.error.signUpPassword",
    kycFormFieldErrorSourceOfTradingFundsIds = "kyc.form.field.error.SourceOfTradingFundsIds",
    kycFormFieldErrorSourceOfWealthIds = "kyc.form.field.error.SourceOfWealthIds",
    kycFormFieldErrorTIN = "kyc.form.field.error.TIN",
    kycFormFieldErrorToken = "kyc.form.field.error.Token",
    kycFormFieldErrorTotalVolumeOfTransactionsLast24MonthsId = "kyc.form.field.error.TotalVolumeOfTransactionsLast24MonthsId",
    kycFormFieldErrorWarrantsFamiliarityId = "kyc.form.field.error.WarrantsFamiliarityId",
    kycFormFieldErrorYear = "kyc.form.field.error.year",
    kycFormFieldErrorYearlyDisposableIncome = "kyc.form.field.error.YearlyDisposableIncome",
    kycFormFieldEstimatedAnnualIncome = "kyc.form.field.EstimatedAnnualIncome",
    kycFormFieldExchangeTradedFundsFamiliarityId = "kyc.form.field.ExchangeTradedFundsFamiliarityId",
    kycFormFieldExchangeTradedFundsFamiliarityId_checkbox = "kyc.form.field.ExchangeTradedFundsFamiliarityId_checkbox",
    kycFormFieldExperienceLength = "kyc.form.field.ExperienceLength",
    kycFormFieldFinancialDerivativesFamiliarityId = "kyc.form.field.FinancialDerivativesFamiliarityId",
    kycFormFieldFinancialDerivativesFamiliarityId_checkbox = "kyc.form.field.FinancialDerivativesFamiliarityId_checkbox",
    kycFormFieldFirstName = "kyc.form.field.FirstName",
    kycFormFieldForexAndCFDsYearsOfExperience = "kyc.form.field.ForexAndCFDsYearsOfExperience",
    kycFormFieldFrequencyOfTradingId = "kyc.form.field.FrequencyOfTradingId",
    kycFormFieldFullName = "kyc.form.field.FullName",
    kycFormFieldIdType = "kyc.form.field.idType",
    kycFormFieldIdentityCardNumber = "kyc.form.field.IdentityCardNumber",
    kycFormFieldIndustryDescription = "kyc.form.field.IndustryDescription",
    kycFormFieldIndustryId = "kyc.form.field.IndustryId",
    kycFormFieldInvestmentHorizonTypeId = "kyc.form.field.InvestmentHorizonTypeId",
    kycFormFieldIsAverageAmountOfCarriedTransactionsPerQuarterExceedsLimit = "kyc.form.field.IsAverageAmountOfCarriedTransactionsPerQuarterExceedsLimit",
    kycFormFieldIsPoliticalPerson = "kyc.form.field.IsPoliticalPerson",
    kycFormFieldIsProfessionalExperienceExceedsOneYear = "kyc.form.field.IsProfessionalExperienceExceedsOneYear",
    kycFormFieldIsSizeOfFinancialInstrumentPortfolioExceedsLimit = "kyc.form.field.IsSizeOfFinancialInstrumentPortfolioExceedsLimit",
    kycFormFieldIsUSACitizen = "kyc.form.field.IsUSACitizen",
    kycFormFieldKnowledgeOfCFDTrading = "kyc.form.field.KnowledgeOfCFDTrading",
    kycFormFieldLastName = "kyc.form.field.LastName",
    kycFormFieldListedSharesId = "kyc.form.field.ListedSharesId",
    kycFormFieldListedSharesId_checkbox = "kyc.form.field.ListedSharesId_checkbox",
    kycFormFieldLoginPassword = "kyc.form.field.loginPassword",
    kycFormFieldMethodsOfDepositId = "kyc.form.field.MethodsOfDepositId",
    kycFormFieldMonth = "kyc.form.field.month",
    kycFormFieldNewPassword = "kyc.form.field.newPassword",
    kycFormFieldOtherEmploymentDetails = "kyc.form.field.OtherEmploymentDetails",
    kycFormFieldPhoneNumber = "kyc.form.field.PhoneNumber",
    kycFormFieldPlannedTradingAmountPerYearId = "kyc.form.field.PlannedTradingAmountPerYearId",
    kycFormFieldPostalCode = "kyc.form.field.PostalCode",
    kycFormFieldPreviousEmploymentDetailsType = "kyc.form.field.PreviousEmploymentDetailsType",
    kycFormFieldPreviousIndustryId = "kyc.form.field.PreviousIndustryId",
    kycFormFieldProductGovernanceDesiredRiskTypeId = "kyc.form.field.ProductGovernanceDesiredRiskTypeId",
    kycFormFieldProductGovernanceInvestingObjectiveTypeId = "kyc.form.field.ProductGovernanceInvestingObjectiveTypeId",
    kycFormFieldProductGovernanceYearlyDisposableIncomeCategoryId = "kyc.form.field.ProductGovernanceYearlyDisposableIncomeCategoryId",
    kycFormFieldProfessionalPolicy = "kyc.form.field.ProfessionalPolicy",
    kycFormFieldProfessionalQualificationTypeId = "kyc.form.field.ProfessionalQualificationTypeId",
    kycFormFieldReferralCode = "kyc.form.field.referralCode",
    kycFormFieldRetypeNewPassword = "kyc.form.field.retypeNewPassword",
    kycFormFieldSavingAndInvestmentId = "kyc.form.field.SavingAndInvestmentId",
    kycFormFieldSignUpCountryCode = "kyc.form.field.signUpCountryCode",
    kycFormFieldSignUpPassword = "kyc.form.field.signUpPassword",
    kycFormFieldSourceOfTradingFundsIds = "kyc.form.field.SourceOfTradingFundsIds",
    kycFormFieldSourceOfWealthIds = "kyc.form.field.SourceOfWealthIds",
    kycFormFieldTIN = "kyc.form.field.TIN",
    kycFormFieldToken = "kyc.form.field.Token",
    kycFormFieldTotalVolumeOfTransactionsLast24MonthsId = "kyc.form.field.TotalVolumeOfTransactionsLast24MonthsId",
    kycFormFieldTradingAccountPurpose = "kyc.form.field.TradingAccountPurpose",
    kycFormFieldTradingVolumeInTheLast12MonthsType = "kyc.form.field.TradingVolumeInTheLast12MonthsType",
    kycFormFieldUsername = "kyc.form.field.username",
    kycFormFieldWarrantsFamiliarityId = "kyc.form.field.WarrantsFamiliarityId",
    kycFormFieldWarrantsFamiliarityId_checkbox = "kyc.form.field.WarrantsFamiliarityId_checkbox",
    kycFormFieldYear = "kyc.form.field.year",
    kycFormFieldYearlyDisposableIncome = "kyc.form.field.YearlyDisposableIncome",
    kycInAppropriateRetypeError = "kyc.in.appropriate.retype.error",
    kycInAppropriateRetypeHere = "kyc.in.appropriate.retype.here",
    kycInAppropriateRetypeInfo = "kyc.in.appropriate.retype.info",
    kycInAppropriateRetypeText = "kyc.in.appropriate.retype.text",
    kycMenuTitleAddress = "kyc.menu.title.Address",
    kycMenuTitleFinancialDetails = "kyc.menu.title.FinancialDetails",
    kycMenuTitleFinancialDetailsInternational = "kyc.menu.title.FinancialDetailsInternational",
    kycMenuTitleIdentityVerification = "kyc.menu.title.IdentityVerification",
    kycMenuTitleKycCongratulation = "kyc.menu.title.KycCongratulation",
    kycMenuTitlePersonalInfo = "kyc.menu.title.PersonalInfo",
    kycMenuTitleTopUp = "kyc.menu.title.TopUp",
    kycMenuTitleTradingExperience = "kyc.menu.title.TradingExperience",
    kycMenuTitleTradingProfile = "kyc.menu.title.TradingProfile",
    kycPageSubtitleDepositLimitQuestions = "kyc.page.subtitle.DepositLimitQuestions",
    kycPageSubtitleDocumentsOverview = "kyc.page.subtitle.DocumentsOverview",
    kycPageSubtitleKycAddressVerification = "kyc.page.subtitle.KycAddressVerification",
    kycPageSubtitleKycCongratulation = "kyc.page.subtitle.KycCongratulation",
    kycPageSubtitleKycCongratulationIdentityVerificationSkipped = "kyc.page.subtitle.KycCongratulation.identityVerificationSkipped",
    kycPageSubtitleKycCongratulationNotAutoApproved = "kyc.page.subtitle.KycCongratulation.notAutoApproved",
    kycPageSubtitleKycCongratulationTopUpSkipped = "kyc.page.subtitle.KycCongratulation.topUpSkipped",
    kycPageSubtitleKycFinancialDetails = "kyc.page.subtitle.KycFinancialDetails",
    kycPageSubtitleKycFinancialDetailsInternational = "kyc.page.subtitle.KycFinancialDetailsInternational",
    kycPageSubtitleKycIdentityVerification = "kyc.page.subtitle.KycIdentityVerification",
    kycPageSubtitleKycPaymentVerification = "kyc.page.subtitle.KycPaymentVerification",
    kycPageSubtitleKycTradingExperience = "kyc.page.subtitle.KycTradingExperience",
    kycPageSubtitleKycTradingExperienceIntentions = "kyc.page.subtitle.KycTradingExperienceIntentions",
    kycPageSubtitleKycTradingExperienceKnowledge = "kyc.page.subtitle.KycTradingExperienceKnowledge",
    kycPageSubtitleKycTradingExperienceKnowledge2 = "kyc.page.subtitle.KycTradingExperienceKnowledge2",
    kycPageSubtitleKycTradingFamiliarity = "kyc.page.subtitle.KycTradingFamiliarity",
    kycPageSubtitleKycTradingProfile = "kyc.page.subtitle.KycTradingProfile",
    kycPageTitleDepositLimitQuestions = "kyc.page.title.DepositLimitQuestions",
    kycPageTitleDocumentsOverview = "kyc.page.title.DocumentsOverview",
    kycPageTitleKycAddress = "kyc.page.title.KycAddress",
    kycPageTitleKycAddressVerification = "kyc.page.title.KycAddressVerification",
    kycPageTitleKycAppropriate = "kyc.page.title.KycAppropriate",
    kycPageTitleKycCnmv = "kyc.page.title.KycCnmv",
    kycPageTitleKycCongratulation = "kyc.page.title.KycCongratulation",
    kycPageTitleKycCongratulationIdentityVerificationSkipped = "kyc.page.title.KycCongratulation.identityVerificationSkipped",
    kycPageTitleKycCongratulationNotAutoApproved = "kyc.page.title.KycCongratulation.notAutoApproved",
    kycPageTitleKycCongratulationTopUpSkipped = "kyc.page.title.KycCongratulation.topUpSkipped",
    kycPageTitleKycFilledOut = "kyc.page.title.KycFilledOut",
    kycPageTitleKycFinancialDetails = "kyc.page.title.KycFinancialDetails",
    kycPageTitleKycFinancialDetailsInternational = "kyc.page.title.KycFinancialDetailsInternational",
    kycPageTitleKycIdentityVerification = "kyc.page.title.KycIdentityVerification",
    kycPageTitleKycInAppropriate = "kyc.page.title.KycInAppropriate",
    kycPageTitleKycPaymentVerification = "kyc.page.title.KycPaymentVerification",
    kycPageTitleKycPersonalInfo = "kyc.page.title.KycPersonalInfo",
    kycPageTitleKycSignature = "kyc.page.title.KycSignature",
    kycPageTitleKycTopUp = "kyc.page.title.KycTopUp",
    kycPageTitleKycTradingExperience = "kyc.page.title.KycTradingExperience",
    kycPageTitleKycTradingExperienceIntentions = "kyc.page.title.KycTradingExperienceIntentions",
    kycPageTitleKycTradingExperienceKnowledge = "kyc.page.title.KycTradingExperienceKnowledge",
    kycPageTitleKycTradingExperienceKnowledge2 = "kyc.page.title.KycTradingExperienceKnowledge2",
    kycPageTitleKycTradingFamiliarity = "kyc.page.title.KycTradingFamiliarity",
    kycPageTitleKycTradingProfile = "kyc.page.title.KycTradingProfile",
    kycPageTitleKycWelcome = "kyc.page.title.KycWelcome",
    kycPoliticalExposedPersonModal1 = "kyc.political.exposed.person.modal.1",
    kycPoliticalExposedPersonModal1a = "kyc.political.exposed.person.modal.1a",
    kycPoliticalExposedPersonModal1b = "kyc.political.exposed.person.modal.1b",
    kycPoliticalExposedPersonModal1c = "kyc.political.exposed.person.modal.1c",
    kycPoliticalExposedPersonModal1d = "kyc.political.exposed.person.modal.1d",
    kycPoliticalExposedPersonModal1e = "kyc.political.exposed.person.modal.1e",
    kycPoliticalExposedPersonModal2 = "kyc.political.exposed.person.modal.2",
    kycPoliticalExposedPersonModal2a = "kyc.political.exposed.person.modal.2a",
    kycPoliticalExposedPersonModal2b = "kyc.political.exposed.person.modal.2b",
    kycPoliticalExposedPersonModal2c = "kyc.political.exposed.person.modal.2c",
    kycPoliticalExposedPersonModal2d = "kyc.political.exposed.person.modal.2d",
    kycPoliticalExposedPersonModal2e = "kyc.political.exposed.person.modal.2e",
    kycServiceAgreement = "kyc.service.agreement",
    kycServiceAgreementLinks = "kyc.service.agreement.links",
    kycSignatureApplicationReviewed = "kyc.signature.application.reviewed",
    leave = "leave",
    liveChat = "liveChat",
    loginUseEmail = "loginUseEmail",
    loginUseToken = "loginUseToken",
    makePhotoDescription = "makePhotoDescription",
    makeSureCoverInformation = "makeSureCoverInformation",
    mmYY = "mmYY",
    moreOptionsCanBeSelected = "moreOptionsCanBeSelected",
    moreProductsCanBeSelected = "moreProductsCanBeSelected",
    mostPopular = "mostPopular",
    needMoreTime = "needMoreTime",
    newPlatformModalDaysLeft = "newPlatform.modal.daysLeft",
    newPlatformModalDescription = "newPlatform.modal.description",
    newPlatformModalProceedWithProfit = "newPlatform.modal.proceedWithProfit",
    newPlatformModalSwitch = "newPlatform.modal.switch",
    newPlatformModalTitle = "newPlatform.modal.title",
    next = "next",
    nextStep = "nextStep",
    no = "no",
    noImNot = "noImNot",
    ok = "ok",
    onlineChat = "onlineChat",
    oops = "oops",
    openingAccount = "openingAccount",
    optional = "optional",
    or = "or",
    passwordHasExpired = "passwordHasExpired",
    pending = "pending",
    pendingApproval = "pendingApproval",
    personalReferenceNumber = "personalReferenceNumber",
    pleaseSpecifyReason = "pleaseSpecifyReason",
    politicalExposedPerson = "politicalExposedPerson",
    poweredBy = "poweredBy",
    print = "print",
    proceed = "proceed",
    professionalAccountApply = "professional.account.apply",
    professionalBecome = "professional.become",
    professionalBenefitBonuses = "professional.benefit.bonuses",
    professionalBenefitCloseout = "professional.benefit.closeout",
    professionalBenefitDescription = "professional.benefit.description",
    professionalBenefitFunds = "professional.benefit.funds",
    professionalBenefitLeverage = "professional.benefit.leverage",
    professionalBenefitProtection = "professional.benefit.protection",
    professionalBenefitRetail = "professional.benefit.retail",
    professionalBrokersNames = "professional.brokers.names",
    professionalEmploymentOther = "professional.employment.other",
    professionalFinancialCfdKnowledge = "professional.financial.cfd.knowledge",
    professionalFinancialRole = "professional.financial.role",
    professionalIllegalDescription = "professional.illegal.description",
    professionalPortfolioInvestment = "professional.portfolio.investment",
    professionalSuccessDescription = "professional.success.description",
    professionalSuccessTitle = "professional.success.title",
    professionalWelcomeSubtitle = "professional.welcome.subtitle",
    professionalWelcomeTitle = "professional.welcome.title",
    readServiceAgreement = "readServiceAgreement",
    reason = "reason",
    referenceNumberDescription = "referenceNumberDescription",
    reject = "reject",
    rejected = "rejected",
    rememberMe = "rememberMe",
    removeAccess = "removeAccess",
    replaceFile = "replaceFile",
    required = "required",
    retakePhoto = "retakePhoto",
    scrollBottomToAcceptTerms = "scrollBottomToAcceptTerms",
    select = "select",
    sendDetailsViaEmail = "sendDetailsViaEmail",
    serverErrorCode10001 = "server.error.code.10001",
    serverErrorCode10002 = "server.error.code.10002",
    serverErrorCode10003 = "server.error.code.10003",
    serverErrorCode101 = "server.error.code.101",
    serverErrorCode1010 = "server.error.code.1010",
    serverErrorCode1011 = "server.error.code.1011",
    serverErrorCode1012 = "server.error.code.1012",
    serverErrorCode1013 = "server.error.code.1013",
    serverErrorCode1014 = "server.error.code.1014",
    serverErrorCode1015 = "server.error.code.1015",
    serverErrorCode1016 = "server.error.code.1016",
    serverErrorCode1017 = "server.error.code.1017",
    serverErrorCode102 = "server.error.code.102",
    serverErrorCode103 = "server.error.code.103",
    serverErrorCode104 = "server.error.code.104",
    serverErrorCode105 = "server.error.code.105",
    serverErrorCode1050 = "server.error.code.1050",
    serverErrorCode1051 = "server.error.code.1051",
    serverErrorCode1053 = "server.error.code.1053",
    serverErrorCode1054 = "server.error.code.1054",
    serverErrorCode106 = "server.error.code.106",
    serverErrorCode107 = "server.error.code.107",
    serverErrorCode108 = "server.error.code.108",
    serverErrorCode109 = "server.error.code.109",
    serverErrorCode8 = "server.error.code.8",
    serverErrorGeneral = "server.error.general",
    serverErrorLimitReached = "server.error.limit.reached",
    serviceAgreement = "serviceAgreement",
    shareInformationPageTitleShareInformationDetails = "shareInformation.page.title.ShareInformationDetails",
    shareInformationPageTitleShareInformationNotice = "shareInformation.page.title.ShareInformationNotice",
    specifiedPepUs = "specifiedPepUs",
    start = "start",
    startTrading = "startTrading",
    startsFrom = "startsFrom",
    stepOf = "stepOf",
    submit = "submit",
    submitCNMV = "submitCNMV",
    swift = "swift",
    takeNewPicture = "takeNewPicture",
    takePhoto = "takePhoto",
    tellUsAboutYou = "tellUsAboutYou",
    termsAndConditions = "termsAndConditions",
    topUp = "topUp",
    topUpDifferentAmount = "topUpDifferentAmount",
    topUpValue = "topUpValue",
    topUpYourAccount = "topUpYourAccount",
    transferDetailsSentToEmail = "transferDetailsSentToEmail",
    tryAgain = "tryAgain",
    upload = "upload",
    uploadAll = "uploadAll",
    uploadFile = "uploadFile",
    validUntil = "validUntil",
    verificationCode = "verificationCode",
    verifyYourIdentity = "verifyYourIdentity",
    weReallySorry = "weReallySorry",
    willDoLater = "willDoLater",
    willTryLater = "willTryLater",
    yes = "yes",
    yesIAm = "yesIAm",
    yourApplicationReviewed = "yourApplicationReviewed",
}
