import {ActionCreatorDefinition, createAction} from "redux-ts";
import {KycStepStatus} from "kbd/enum/KycStepStatus";
import {KycStepType} from "kbd/enum/KycStepType";
import {KycCnmvState, KycStep} from "kbd/core/redux/kyc/KycReduxState";
import {PageType} from "kbd/enum/PageType";
import {ComplianceFormInfo} from "kbd/protocol/ComplianceFormInfo";
import {IPage} from "kbd/pages/IPage";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {TSMap} from "typescript-map";
import {AnswerInfo} from "kbd/protocol/AnswerInfo";
import {ActionType} from "kbd/core/common/action/ActionType";
import {ActivityTypeInfo} from "kbd/protocol/ActivityTypeInfo";
import {GetUrlType} from "platform/protocol/enum/GetUrlType";
import {FinancialDetailsType} from "kbd/enum/FinancialDetailsType";

export const StartKycType: string =  "StartKyc";
export const SetFinancialDetailsTypeType: string =  "SetFinancialDetailsType";
export const SetDocumentsStandaloneType: string =  "SetDocumentsStandalone";
export const SetKycStartedType: string =  "SetKycStarted";
export const SetKycFinishedType: string =  "SetKycFinished";
export const SetFailedToInitType: string =  "SetFailedToInit";
export const SetHasSignatureType: string =  "SetHasSignature";
export const SetLeaveConfirmationType: string =  "SetLeaveConfirmation";
export const SetAddressExtendedType: string =  "SetAddressExtended";
export const UsedGoogleAutoCompleteType: string =  "UsedGoogleAutoComplete";
export const SetKycStepsType: string =  "SetKycSteps";
export const UpdateKycStepsType: string =  "UpdateKycSteps";
export const SetKycStepStatusType: string =  "SetKycStepStatus";
export const SetClickFormSubmitType: string =  "SetClickFormSubmit";
export const SubmittingFormType: string =  "SubmittingForm";
export const SetSubmitFormErrorType: string =  "SetSubmitFormError";
export const DoSaveKycFormType: string =  "DoSaveKycForm";
export const SetKycFormType: string =  "SetKycForm";
export const SkipIdentityVerificationType: string =  "SkipIdentityVerification";
export const GoToTradingPlatformType: string =  "GoToTradingPlatform";
export const SetCnmvStateType: string =  "SetCnmvState";
export const SetConfiguredCountriesType: string =  "SetConfiguredCountries";
export const DoGetTinDocUrlType: string =  "DoGetTinDocUrl";
export const SetTinDocUrlType: string =  "SetTinDocUrl";
export const FetchAnswersForQuestionsType: string =  "FetchAnswersForQuestions";
export const SetAnswersForQuestionsType: string =  "SetAnswersForQuestions";
export const DoGetURLByTypeType: string =  "DoGetURLByType";
export const SetURLByTypeType: string =  "SetURLByType";
export const DoRegisterActivityType: string =  "DoRegisterActivity";
export const DoReportCNMVType: string =  "DoReportCNMV";
export const DoRecordPrintClickType: string =  "DoRecordPrintClick";
export const DoReportAgreeWithAppropriateType: string =  "DoReportAgreeWithAppropriate";
export const DoReportAgreeWithInAppropriateType: string =  "DoReportAgreeWithInAppropriate";
export const DoSetSignatureType: string =  "DoSetSignature";
export const SetSignatureApprovedType: string =  "SetSignatureApproved";
export const SetAcknowledgePoliticalExposedDescriptionType: string =  "SetAcknowledgePoliticalExposedDescription";
export const SetAcknowledgeTINDescriptionType: string =  "SetAcknowledgeTINDescription";
export const SetBirthCountriesType: string = "SetBirthCountries";

export interface SetFinancialDetailsTypePayload {

    financialDetailsType: FinancialDetailsType;
}

export interface SetFailedToInitPayload {

    failed: boolean;
}

export interface SetLeaveConfirmationPayload {

    tryToLeave: boolean;
}

export interface SetAddressExtendedPayload {

    extended: boolean;
}

export interface UsedGoogleAutoCompletePayload {

    used: boolean;
}

export interface SetKycStepsPayload {

    steps: KycStep[];
    page: IPage;
}

export interface UpdateKycStepsPayload {

    steps: TSMap<KycStepType, KycStep>;
}

export interface SetKycStepStatusPayload {

    stepType: KycStepType;
    status: KycStepStatus;
}

export interface SetClickFormSubmitPayload {

    pageType: PageType;
    submit: boolean;
}

export interface SubmittingFormPayload {

    submitting: boolean;
}

export interface SetSubmitFormErrorPayload {

    error: boolean;
}

export interface DoSaveKycFormPayload {

    pageType: PageType;
    stepType: KycStepType;
    form: any;
}

export interface SetKycFormPayload {

    complianceForm: ComplianceFormInfo;
    IsAppropriate: boolean;
}

export interface SetCnmvStatePayload {

    state: KycCnmvState;
}

export interface SetConfiguredCountriesPayload {

    countries: TSMap<number, CountryInfo>;
    namePerCountry: TSMap<string, CountryInfo>;
}

export interface DoGetTinDocUrlPayload {

    countryName: string;
}

export interface SetTinDocUrlPayload {

    url: string;
}

export interface FetchAnswersForQuestionsPayload {

    pageType: PageType;
}

export interface SetAnswersForQuestionsPayload {

    answers: {[key: string]: AnswerInfo[]};
}

export interface DoGetURLByTypePayload {

    urlType: GetUrlType;
    actionType?: ActionType;
}

export interface SetURLByTypePayload {

    urlType: GetUrlType;
    url: string;
}

export interface DoRegisterActivityPayload {

    activityType: ActivityTypeInfo;
}

export interface DoSetSignaturePayload {

    ShouldSetFinalSignature?: boolean;
    ShouldSetKycSignatureOnFinalSignatureAlso?: boolean;
}

export interface DoReportCNMVPayload {

    cnmv: string;
}

export interface DoReportAgreeWithInAppropriatePayload {

    agreed: boolean;
}

export interface SetSignatureApprovedPayload {

    approved: boolean;
}

export interface  SetBirthCountriesPayload {

    countries: TSMap<string, CountryInfo>;
}

export const StartKyc: ActionCreatorDefinition<any, any> = createAction<any>(StartKycType);
export const SetFinancialDetailsType: ActionCreatorDefinition<SetFinancialDetailsTypePayload, any> = createAction<SetFinancialDetailsTypePayload>(SetFinancialDetailsTypeType);
export const SetDocumentsStandalone: ActionCreatorDefinition<any, any> = createAction<any>(SetDocumentsStandaloneType);
export const SetKycStarted: ActionCreatorDefinition<any, any> = createAction<any>(SetKycStartedType);
export const SetKycFinished: ActionCreatorDefinition<any, any> = createAction<any>(SetKycFinishedType);
export const SetFailedToInit: ActionCreatorDefinition<SetFailedToInitPayload, any> = createAction<SetFailedToInitPayload>(SetFailedToInitType);
export const SetHasSignature: ActionCreatorDefinition<any, any> = createAction<any>(SetHasSignatureType);
export const SetLeaveConfirmation: ActionCreatorDefinition<SetLeaveConfirmationPayload, any> = createAction<SetLeaveConfirmationPayload>(SetLeaveConfirmationType);
export const SetAddressExtended: ActionCreatorDefinition<SetAddressExtendedPayload, any> = createAction<SetAddressExtendedPayload>(SetAddressExtendedType);
export const UsedGoogleAutoComplete: ActionCreatorDefinition<UsedGoogleAutoCompletePayload, any> = createAction<UsedGoogleAutoCompletePayload>(UsedGoogleAutoCompleteType);
export const SetKycSteps: ActionCreatorDefinition<SetKycStepsPayload, any> = createAction<SetKycStepsPayload>(SetKycStepsType);
export const UpdateKycSteps: ActionCreatorDefinition<UpdateKycStepsPayload, any> = createAction<UpdateKycStepsPayload>(UpdateKycStepsType);
export const SetKycStepStatus: ActionCreatorDefinition<SetKycStepStatusPayload, any> = createAction<SetKycStepStatusPayload>(SetKycStepStatusType);
export const SetClickFormSubmit: ActionCreatorDefinition<SetClickFormSubmitPayload, any> = createAction<SetClickFormSubmitPayload>(SetClickFormSubmitType);
export const SubmittingForm: ActionCreatorDefinition<SubmittingFormPayload, any> = createAction<SubmittingFormPayload>(SubmittingFormType);
export const SetSubmitFormError: ActionCreatorDefinition<SetSubmitFormErrorPayload, any> = createAction<SetSubmitFormErrorPayload>(SetSubmitFormErrorType);
export const DoSaveKycForm: ActionCreatorDefinition<DoSaveKycFormPayload, any> = createAction<DoSaveKycFormPayload>(DoSaveKycFormType);
export const SetKycForm: ActionCreatorDefinition<SetKycFormPayload, any> = createAction<SetKycFormPayload>(SetKycFormType);
export const SkipIdentityVerification: ActionCreatorDefinition<any, any> = createAction<any>(SkipIdentityVerificationType);
export const GoToTradingPlatform: ActionCreatorDefinition<any, any> = createAction<any>(GoToTradingPlatformType);
export const SetCnmvState: ActionCreatorDefinition<SetCnmvStatePayload, any> = createAction<SetCnmvStatePayload>(SetCnmvStateType);
export const SetConfiguredCountries: ActionCreatorDefinition<SetConfiguredCountriesPayload, any> = createAction<SetConfiguredCountriesPayload>(SetConfiguredCountriesType);
export const DoGetTinDocUrl: ActionCreatorDefinition<DoGetTinDocUrlPayload, any> = createAction<DoGetTinDocUrlPayload>(DoGetTinDocUrlType);
export const SetTinDocUrl: ActionCreatorDefinition<SetTinDocUrlPayload, any> = createAction<SetTinDocUrlPayload>(SetTinDocUrlType);
export const FetchAnswersForQuestions: ActionCreatorDefinition<FetchAnswersForQuestionsPayload, any> = createAction<FetchAnswersForQuestionsPayload>(FetchAnswersForQuestionsType);
export const SetAnswersForQuestions: ActionCreatorDefinition<SetAnswersForQuestionsPayload, any> = createAction<SetAnswersForQuestionsPayload>(SetAnswersForQuestionsType);
export const DoGetURLByType: ActionCreatorDefinition<DoGetURLByTypePayload, any> = createAction<DoGetURLByTypePayload>(DoGetURLByTypeType);
export const SetURLByType: ActionCreatorDefinition<SetURLByTypePayload, any> = createAction<SetURLByTypePayload>(SetURLByTypeType);
export const DoRegisterActivity: ActionCreatorDefinition<DoRegisterActivityPayload, any> = createAction<DoRegisterActivityPayload>(DoRegisterActivityType);
export const DoReportCNMV: ActionCreatorDefinition<DoReportCNMVPayload, any> = createAction<DoReportCNMVPayload>(DoReportCNMVType);
export const DoRecordPrintClick: ActionCreatorDefinition<any, any> = createAction<any>(DoRecordPrintClickType);
export const DoReportAgreeWithAppropriate: ActionCreatorDefinition<DoReportAgreeWithInAppropriatePayload, any> = createAction<DoReportAgreeWithInAppropriatePayload>(DoReportAgreeWithAppropriateType);
export const DoReportAgreeWithInAppropriate: ActionCreatorDefinition<DoReportAgreeWithInAppropriatePayload, any> = createAction<DoReportAgreeWithInAppropriatePayload>(DoReportAgreeWithInAppropriateType);
export const DoSetSignature: ActionCreatorDefinition<DoSetSignaturePayload, any> = createAction<DoSetSignaturePayload>(DoSetSignatureType);
export const SetSignatureApproved: ActionCreatorDefinition<SetSignatureApprovedPayload, any> = createAction<SetSignatureApprovedPayload>(SetSignatureApprovedType);
export const SetAcknowledgePoliticalExposedDescription: ActionCreatorDefinition<any, any> = createAction<any>(SetAcknowledgePoliticalExposedDescriptionType);
export const SetAcknowledgeTINDescription: ActionCreatorDefinition<any, any> = createAction<any>(SetAcknowledgeTINDescriptionType);
export const SetBirthCountries: ActionCreatorDefinition<SetBirthCountriesPayload, any> = createAction<SetBirthCountriesPayload>(SetBirthCountriesType);
